<template>
  <ion-app>
    <ion-router-outlet v-if="appData && sharedState.ui" animated="false" />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, isPlatform } from '@ionic/vue';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { defineComponent } from 'vue';
import { store } from '@/store';
import { storage } from '@/storage';
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics, logEvent } from "firebase/analytics";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";


export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
  },
  data() {
    return {
      appData: null,
      storage: storage,
      sharedState: store.state,
      lang: null,
      firebaseConfig: {
        apiKey: "AIzaSyDfXyhKTSjS0fL5pyPBLEO-qJrcVmWuIPA",
        authDomain: "namisd-oscer.firebaseapp.com",
        projectId: "namisd-oscer",
        storageBucket: "namisd-oscer.appspot.com",
        messagingSenderId: "687138132793",
        appId: "1:687138132793:web:ab9b8f2fefb9952c44cee8",
        measurementId: "G-2498S6T655"
      }
    }
  },
  methods: {
    getAppData: async function() {
      return this.appData = await store.getAppData();
    },
    updateUiData: async function() {
      this.lang = await store.getLanguage();
      await store.updateUiData(this.lang);
    },
    updateDeviceInfo: async function() {
      let os = store.getItem('deviceType');
      if(isPlatform('ios')) os = 'ios';
      if(isPlatform('android')) os = 'android';
      await store.updateItem('deviceType', os);
    },
    checkNetworkStatus: async function() {
      const status = await Network.getStatus();
      await store.updateItem('networkAvailable', status.connected);
      await this.updateDataPrefix();
    },
    updateDataPrefix: async function() {
       // Set to use local data source
      let dataPrefix = '';
      const prodUrl = 'https://app.oscer.org';
      const stageUrl = 'https://dev.oscer.org';
      
      // If network is available we will use remote data sources
      if (await this.sharedState.networkAvailable) {
        dataPrefix = prodUrl;
        if (window.location?.origin?.includes(stageUrl) === true) {
          dataPrefix = stageUrl;
        }
        if (!Capacitor.isNativePlatform() && window.location?.origin?.includes('localhost') === true) {
          dataPrefix = stageUrl;
        }
      }
      await store.updateItem('dataPrefix', dataPrefix);
    },
    updateCategoryData: async function() {
      const lang = this.lang || await store.getLanguage();
      await store.updateCategoryData(lang);
    },
    initGA: async function() {
      // Initialize Firebase Analytics
      FirebaseAnalytics.initializeFirebase(this.firebaseConfig);
      FirebaseAnalytics.logEvent({ name: "init_app" });
    }
  },
  watch: {
    'sharedState.language': async function() {
      await this.updateUiData();
      await store.updateAppData();
      await this.getAppData();
      await this.updateCategoryData();
    },
    'sharedState.networkAvailable': async function() {
      await this.updateDataPrefix();
    }
     
  },
  async beforeMount() {
    await this.checkNetworkStatus();
    await this.updateDeviceInfo();
    await this.getAppData();
    await this.updateUiData();
    await this.updateCategoryData();
    await this.initGA();
  }
});
</script>