import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { IonicVue } from '@ionic/vue';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
/* Optional CSS utils */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/text-alignment.css';
/* Theme variables */
import './theme/variables.css';
/* Theme styles */
import './theme/main.css';

const config = {
  swipeBackEnabled: true,
  hardwareBackButton: true,
  navAnimation: null,
  devTools: true
}

const app = createApp(App)
  .use(IonicVue, config)
  .use(router);

router.isReady().then( () => app.mount('#app') );