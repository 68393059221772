<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="dismissModal">Cancel</ion-button>
      </ion-buttons>
      <ion-title>Filter Resources</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content :dir="direction">
    <ion-list>
      <ion-item v-for="filter in availableFilters" :key="filter.id">
        <ion-checkbox justify="start" label-placement="end" @ionChange="updateFilterList(filter.slug, $event)" :checked="isFilterOn(filter.slug)" v-html="filter.name"></ion-checkbox>
      </ion-item>
    </ion-list>
  </ion-content>
  <ion-footer>
    <ion-toolbar>
      <ion-buttons>
        <ion-button class="clear-btn" @click="clearFilters">Clear all</ion-button>
        <ion-button :class="'view-results-btn' + ((resultNumber === 0 || selectedFilters.length === 0) ? '' : ' active')" @click="viewResults">View Results (<span>{{ resultNumber }}</span>)</ion-button>
      </ion-buttons>
      </ion-toolbar>
  </ion-footer>
</template>

<script>
import { IonButtons, IonButton, IonCheckbox, IonContent, IonFooter, IonHeader, IonItem, IonList, IonToolbar, IonTitle, alertController, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { store } from '@/store';

export default defineComponent({
  name: 'ResourceFiltersModal',
  components: { IonButtons, IonButton, IonCheckbox, IonContent, IonFooter, IonHeader, IonItem, IonList, IonToolbar, IonTitle },
  props: {
    page: { type: String },
  },
  data() {
    return {
      sharedState: store.state,
      selectedFilters: [],
      resources: [],
      filteredResources: [],
      resultNumber: 0,
      availableFilters: null
    }
  },
  computed: {
    direction: function() { return ['ar','fa'].includes(this.sharedState.language) ? 'rtl' : 'ltr' }
  },
  methods: {
    /**
     * Clears selected filters
     */
    clearFilters() {
      this.sharedState.resourceFilters = [];
      this.sharedState.filteredResources = [];
      this.resultNumber = this.resources.length;
      this.selectedFilters = [];

      // Navigate to the resources page
      this.$router.push('/resources');
    },
    /**
     * Dismisses the modal
     */
    dismissModal() {
      modalController.dismiss();
    },
    /**
     * Filters resources by the currently selected filters
     */
    filterResources: async function() {
      let resourceItems = [];
      if (this.resources && this.selectedFilters.length !== 0) {
        // Loop through resources and store the items w/ the selected filters in resourceItems array
        this.resources.forEach((resource) => {
          if (resource.tags && this.includesAll(this.selectedFilters, resource.tags)) {
            resourceItems.push(resource);
          }
        });
      }
      this.filteredResources = resourceItems;
      this.resultNumber = (this.selectedFilters.length === 0) ? this.resources.length : this.filteredResources.length;
    },
    /**
     * Gets the resources & sets the initial state
     */
    getResourcesWithFilters: async function() {
      if (this.sharedState.resourcesForFilters) {
        this.resources = this.sharedState.resourcesForFilters;

        // If there are saved filters, update result number & checked filters
        if (this.sharedState.resourceFilters.length !== 0) {
          this.resultNumber = this.sharedState.filteredResources.length;
          this.selectedFilters = [...this.sharedState.resourceFilters];
        } else {
          this.resultNumber = this.resources.length;
        }
      }
    },
    /**
     * Returns true if all items in searchList are included in items
     */
    includesAll: function(searchList, items) {
      for (let i = 0; i < searchList.length; i++) {
        if (!items.includes(searchList[i])) return false;
      }
      return true;
    },
    /**
     * Returns turn if filter was saved in store as selected
     */
    isFilterOn(filter) {
      return this.sharedState.resourceFilters.includes(filter);
    },
    /**
     * Presents the ion-alert component w/ the corresponding error message
     */
    async presentAlert(type) {
      if (!type) return;
      let config = { buttons: ['OK'] };
      switch (type) {
        case 'no-filters':
          config.header = 'Error';
          config.message = 'Please select a filter to view the results';
          break;
        case 'no-results':
          config.header = 'No Results';
          config.message = 'Try deselecting a few filters to generate results.';
          break;
      }

      const alert = await alertController.create(config);
      await alert.present();
    },
    /**
     * Updates the filter list depending on the value of the checkbox
     */
    async updateFilterList(value, e) {
      if (e.target.checked) {
        this.selectedFilters.push(value);
      } else {
        this.selectedFilters = this.selectedFilters.filter((item) => item !== value);
      }

      // Filters the resources
      await this.filterResources();
    },
    /**
     * Handles click of View Results button
     * Updates the stored resource filters and navigates to the filtered resources
     */
    async viewResults() {
      // If no filters have been selected, show the error message
      if (this.selectedFilters.length === 0) {
        await this.presentAlert('no-filters');
        return;
      }

      // If there are no results, show the error message
      if (this.resultNumber === 0) {
        await this.presentAlert('no-results');
        return;
      }
      
      this.sharedState.resourceFilters = [...this.selectedFilters];
      this.selectedFilters = [];
      this.sharedState.filteredResources = this.filteredResources;
  
      modalController.dismiss();
      this.$router.push('/resources');
    },
  },
  async beforeMount() {
    await this.getResourcesWithFilters();
    this.availableFilters = await store.getResourceFilterData();
    this.filteredResources = [...this.sharedState.filteredResources];
  },
});
</script>


<style scoped>
  ion-footer ion-toolbar {
    --min-height: 75px;
  }

  ion-footer ion-buttons {
    justify-content: center;
  }

  .clear-btn {
    --color: #000;
    background: #fff;
    border: 1.5px solid #000;
    border-radius: 10px;
    font-size: 14px;
    padding: 10px 25px;
  }

  .clear-btn::part(native),
  .view-results-btn::part(native) {
    min-height: 0;
    padding: 0;
  }

  .view-results-btn {
    --color: #000;
    background: #adacac;
    border-radius: 10px;
    font-size: 14px;
    padding: 10px 27px;
  }

  .view-results-btn.active {
    background: var(--ion-color-secondary);
  }
</style>

<style>
  /* Not scoped since modal is outside of component */
  .filter-modal {
    --height: calc(100dvh - 60px);
    align-items: end;
  }

  .filter-modal ion-header ion-toolbar:first-of-type {
    padding-top: 0;
  }

  .filter-modal ion-header ion-button {
    --color: #fb0505;
    position: absolute;
  }
</style>