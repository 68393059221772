<template>
  <ion-page>
    <ion-tabs v-if="sharedState.ui" :key="sharedState.language">
      <AppHeader slot="top" />

      <ion-router-outlet></ion-router-outlet>

      <ion-tab-bar slot="bottom" dir="ltr">

        <ion-tab-button tab="tab1" href="/home#">
          <ion-icon :icon="home" />
          <ion-label class="ion-text-wrap">{{ sharedState.ui.tabs.Home }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab2" href="/resources">
          <ion-icon :icon="folder" />
          <ion-label class="ion-text-wrap">{{ sharedState.ui.tabs.Resources }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab3" :href="`/home/subsection/crisis-hotlines-${sharedState.language}`">
          <ion-icon :icon="call" />
          <ion-label class="ion-text-wrap">{{ sharedState.ui.tabs.Hotlines ?? 'Crisis' }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab4" href="/relax">
          <ion-icon :icon="musicalNotes" />
          <ion-label class="ion-text-wrap">{{ sharedState.ui.tabs.Relax }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab5" href="/settings">
          <ion-icon :icon="settings" />
          <ion-label class="ion-text-wrap">{{ sharedState.ui.tabs.Settings }}</ion-label>
        </ion-tab-button>

      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { IonPage, IonRouterOutlet, IonTabBar, IonTabs, IonTabButton, IonLabel, IonIcon } from '@ionic/vue';
import { home, folder, call, musicalNotes, settings } from 'ionicons/icons';
import AppHeader from '@/components/AppHeader.vue';
import { store } from '@/store';

export default {
  name: 'Tabs',
  components: { AppHeader, IonPage, IonRouterOutlet, IonTabBar, IonTabs, IonTabButton, IonLabel, IonIcon },
  setup() {
    return {
      home,
      folder,
      call,
      musicalNotes,
      settings,
    }
  },
  data() {
    return {
      sharedState: store.state,
    }
  },
  async mounted() {
    const lang = await store.getLanguage();
    const dir = (lang == 'ar' || lang == 'fa') ? 'rtl' : 'ltr';
    const thisEl = this.$el.querySelector('ion-router-outlet');
    thisEl.setAttribute('dir', dir);
    thisEl.setAttribute('animated', 'false');
  }

}
</script>

<style scoped>
ion-tab-bar { min-height: 70px; }

ion-tab-button { width: 20%; }

ion-tab-button::part(native) { padding: 0 0.125em; }

ion-label { font-size: 10px !important; }
</style>