<template>
  <div>
    <audio class="audio" hidden loop></audio>
    <ion-button @click="toggleAudioPlaying">
      <ion-icon slot="icon-only" v-if="sharedState.audio.playing" :icon="volumeHigh"></ion-icon>
      <ion-icon slot="icon-only" v-if="sharedState.audio.src && !sharedState.audio.playing" :icon="volumeMute"></ion-icon>
    </ion-button>
  </div>
</template>

<script>
import { IonIcon, IonButton } from '@ionic/vue';
import { volumeHigh, volumeMute } from 'ionicons/icons';
import { store } from '@/store';
import { storage } from '@/storage';

export default  {
  name: 'AudioPlayer',
  components: { IonIcon, IonButton },
  setup() {
    return {
      volumeHigh,
      volumeMute
    }
  },
  data() {
    return {
      sharedState: store.state,
      storage: storage,
      audioEl: null,
    }
  },
  methods: {
    toggleAudioPlaying() {
      store.toggleAudioPlaying();
    }
  },
  watch: {
    'sharedState.audio.playing': function(val) {
      val ? this.audioEl.play() : this.audioEl.pause();
    },
    'sharedState.audio.src': function(val) {
      this.audioEl.src = val;
      if (this.sharedState.audio.playing) this.audioEl.play();
    },
  },
  mounted() {
    this.audioEl = this.$el.querySelector('audio');
  }
}
</script>